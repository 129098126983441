import React from 'react';
import { formatReal } from '../../../../../../../utils/format';
import { convertToXLSX } from '../../../../../../../utils/download';
import { useModal } from '../../../../../../../hooks/Modal';
interface IProps {
    data: Record<string, any>[],
    title: string;
    groupBy: string;
    groupName
}

const ReportTable = ({ data, title, groupBy, groupName, productFields }) => {

    const { addModal } = useModal();

    const exportOpenData = (list) => {

        const items: Record<string, any>[] = [];

        list?.map(item => {

            const itemContent = [item?.sale_hash, item?.comprador, item?.payment_method_id, item?.payment_status_id, item?.product, item?.haveClientID, item?.name, item?.email, item?.phone, item?.document_number, item?.company, item?.position, item?.extra_1 ? item?.extra_1 : item?.birth_date, item?.state || '', item?.city || '', item?.country || '', item?.disability_id || 'Não', formatReal(item?.total_with_fees), formatReal(item?.fees), formatReal(item?.total), formatReal(item?.tax), formatReal(item?.net_total), item?.coupon, item?.cortesia, item?.fields_text, item?.criado_em, item?.pago_em, item?.know_about, item?.term]

            productFields?.map(i => {
                itemContent.push(item?.fields?.[i?.field] || '');
            })

            items.push(itemContent);

        })


        items?.sort((a, b) => `${a?.[2]}-${a?.[4]}` < `${b?.[2]}-${b?.[4]}` ? -1 : `${a?.[2]}-${a?.[4]}` > `${b?.[2]}-${b?.[4]}` ? 1 : 0)

        const header = ['Protocolo da venda', 'Comprador', 'Forma de pagamento', 'Status de pagamento', 'Produto', 'Transferido?', 'Nome', 'E-mail', 'Telefone', 'CPF', 'Empresa', 'Cargo', 'Data de nascimento', 'Estado', 'Cidade', 'País', 'Deficiência', 'Bruto com juros', 'Juros de parcelamento', 'Bruto', 'Taxas', 'Líquido', 'Cupom', 'Cortesia', 'Campo adicional', 'Data de criação', 'Data de pagamento', 'Como soube do evento', 'Termo'];
        productFields?.map(i => {
            header.push(i?.title);
        })

        items.unshift(header);

        convertToXLSX(items);

    }

    const exportTable = (list, totalItem) => {

        const items: Record<string, any>[] = [];

        list?.map(item => {

            items.push([item?.group, item?.quantity, formatReal(item?.total_with_fees), formatReal(item?.fees), formatReal(item?.total), formatReal(item?.tax), formatReal(item?.net_total)]);

        })
        items.push(['Total', totalItem?.quantity, formatReal(totalItem?.total_with_fees), formatReal(totalItem?.fees), formatReal(totalItem?.total), formatReal(totalItem?.tax), formatReal(totalItem?.net_total)]);



        items?.sort((a, b) => `${a?.[2]}-${a?.[4]}` < `${b?.[2]}-${b?.[4]}` ? -1 : `${a?.[2]}-${a?.[4]}` > `${b?.[2]}-${b?.[4]}` ? 1 : 0)
        items.unshift([groupName, 'Quantidade', 'Bruto com juros', 'Juros de parcelamento', 'Bruto', 'Taxas', 'Líquido']);

        convertToXLSX(items);

    }


    const showData = (sortedList) => {


        addModal({
            title: '', theme: 'whiteModal', content: <> <table className="tableRef" style={{ width: '100%', maxWidth: '100%' }}>
                <tr>
                    <th>Comprador</th>
                    <th>Ingresso</th>
                    <th>Forma PG</th>
                    <th>Status</th>
                    <th>Titular</th><th>E-mail</th><th>Telefone</th><th>CPF</th><th>Empresa</th><th>Cargo</th><th>Data de nascimento</th><th>Estado</th><th>Município</th><th>País</th><th>Deficiência</th><th>Bruto com juros</th><th>Juros de parcelamento</th><th>Bruto</th>    <th>Taxas</th>  <th>Líquido</th><th>Cupom</th><th>Cortesia</th><th>Campo adicional</th><th>Data de criação</th><th>Data de pagamento</th><th>Como soube do evento</th><th>Termo</th>
                    {productFields?.map(i => {
                        return <th>{i?.title}</th>
                    })}

                </tr>
                {sortedList?.map(item => {
                    return <tr key={`resumo-vendas-${item?._id?.toString()}`}>
                        <td>{item?.comprador} </td>
                        <td>{item?.product} </td>
                        <td>{item?.payment_method_id}</td>
                        <td>{item?.payment_status_id}</td>
                        <td>{item?.name}</td>
                        <td>{item?.email}</td>
                        <td>{item?.phone}</td>
                        <td>{item?.document_number}</td>
                        <td>{item?.company}</td>
                        <td>{item?.position}</td>
                        <td>{item?.birth_date}</td>
                        <td>{item?.state || ''}</td>
                        <td>{item?.city || ''}</td>
                        <td>{item?.country || ''}</td>
                        <td>{item?.disability_type || 'Não'}</td>
                        <td>R$ {formatReal(item?.total_with_fees)}</td>
                        <td>R$ {formatReal(item?.fees)}</td>
                        <td>R$ {formatReal(item?.total)}</td>
                        <td>R$ {formatReal(item?.tax)}</td>
                        <td>R$ {formatReal(item?.net_total)}</td>


                        <td>{item?.coupon}</td>
                        <td>{item?.cortesia}</td>
                        <td>{item?.fields_text}</td>
                        <td>{item?.criado_em}</td>
                        <td>{item?.pago_em}</td>
                        <td>{item?.know_about}</td>
                        <td>{item?.term}</td>
                        {productFields?.map(i => {
                            return <td>{item?.fields?.[i?.field] || ''}</td>
                        })
                        }
                    </tr>
                })}

            </table> </>






        })

    }




    /* group, quantity, Bruto com juros	Juros de parcelamento	Bruto	Taxas	Líquido */

    const totalItem = {
        quantity: 0,
        total_with_fees: 0,
        fees: 0,
        total: 0,
        tax: 0,
        net_total: 0


    }


    const grouped = data?.reduce((prev, item) => {

        const index = prev.findIndex(content => content?.group === item?.[groupBy]);

        if (index >= 0) {

            prev[index].quantity += 1;
            prev[index].total_with_fees += item?.total_with_fees;
            prev[index].fees += item?.fees;
            prev[index].total += item?.total;
            prev[index].tax += item?.tax;
            prev[index].net_total += item?.net_total;
            prev[index].contents.push(item)

        }
        else {
            prev.push({
                group: item?.[groupBy],
                quantity: 1,
                total_with_fees: item?.total_with_fees,
                fees: item?.fees,
                total: item?.total,
                tax: item?.tax,
                net_total: item?.net_total,
                contents: [item]
            })

        }

        totalItem.quantity += 1;
        totalItem.total_with_fees += item?.total_with_fees;
        totalItem.fees += item?.fees;
        totalItem.total += item?.total;
        totalItem.tax += item?.tax;
        totalItem.net_total += item?.net_total;


        return prev;

    }, [])


    return <div className='column-start gap-sm' style={{ width: '100%' }}>
        <h2 style={{ color: '#333', marginTop: '20px' }}>{title}</h2>
        <div className='row gap-sm'>
            <button className='greenButton2' onClick={() => exportTable(grouped, totalItem)}>Exportar Tabela</button>
            <button className='greenButton2' onClick={() => showData(data)}>Visualizar dados abertos</button>
            <button className='greenButton2' onClick={() => exportOpenData(data)}>Exportar dados abertos</button>
        </div>
        <table className='tableRef' style={{ width: '100%', margin: '10px 0px', maxWidth: '1200px' }} >
            <tbody>
                <tr>
                    <td>{groupName}</td><td>QTD</td><td>Total com juros</td>
                    <td>Juros</td>
                    <td>ToTal sem juros</td>
                    <td>Taxas</td>
                    <td>Líquido</td>
                </tr>
                {grouped.map(item => {

                    return <tr>
                        <td>{item?.group}</td>
                        <td>{item?.quantity}</td>
                        <td>R$ {formatReal(item?.total_with_fees)}</td>
                        <td> R$ {formatReal(item?.fees)}</td>
                        <td> R$ {formatReal(item?.total)}</td>
                        <td> R$ {formatReal(item?.tax)}</td>
                        <td>R$ {formatReal(item?.net_total)} </td>

                    </tr>

                })}

                <tr>
                    <td style={{ fontWeight: 'bold' }}>Total</td>
                    <td style={{ fontWeight: 'bold' }}>{totalItem?.quantity}</td>
                    <td style={{ fontWeight: 'bold' }}>R$ {formatReal(totalItem?.total_with_fees)}</td>
                    <td style={{ fontWeight: 'bold' }}> R$ {formatReal(totalItem?.fees)}</td>
                    <td style={{ fontWeight: 'bold' }}> R$ {formatReal(totalItem?.total)}</td>
                    <td style={{ fontWeight: 'bold' }}> R$ {formatReal(totalItem?.tax)}</td>
                    <td style={{ fontWeight: 'bold' }}>R$ {formatReal(totalItem?.net_total)} </td>

                </tr>
            </tbody>
        </table>
    </div>
}

export default ReportTable;