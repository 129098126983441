import React, { useState } from 'react';
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'
import { Page, Canvas, Svg, PDFViewer, PDFDownloadLink, Text, View, Document, StyleSheet, Image, Font, } from '@react-pdf/renderer';
import { urlWebsite } from '../../../../../../config';
import QRCode from 'qrcode.react';
import api from '../../../../../../services/api';
import { useModal } from '../../../../../../hooks/Modal';
import { FaDownload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { handleCertificateDownload } from '../ExportPDF';
import { useToast } from '../../../../../../hooks/Toast';

interface IProps {
    lineData: Record<string, any>;
}

const PrepareModal: React.FC = () => {

    const { addModal } = useModal();
    const [certificates, setCertificates] = useState<Array<Record<string, any>>>();
    const { configuration } = useConfiguration();
    const { projectHash = configuration?.url } = useParams<Record<string, any>>();
    const { addToast } = useToast();

    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    const doDownload = async (certificate) => {
        await timeout(500);
        handleCertificateDownload({ certificate: certificate });

        return true;

    }

    const startDownload = async (certItems) => {

        let x = 0;
        const length = certItems?.length;

        while (x < length) {
            await doDownload(certItems[x]);
            addToast({ title: `Exportado: ${x + 1}/${length}` })
            x = x + 1;
        }




    }

    const addAllCerts = async () => {

        const certs = await api.get(`/download-all-certificates/${projectHash}`)

        setCertificates(certs?.data);

        addModal({
            theme: 'whiteModalMini',
            title: '', content: <>
                <p style={{ fontSize: '14px', padding: '10px', color: '#333', textAlign: 'center' }}>Tempo estimado de download: {parseInt(((certs?.data?.length / 60) / 2)?.toString(), 10) + 1} minutos</p>
                <button className='defaultButton' onClick={() => startDownload(certs?.data)}>Download</button>


            </>
        })


    }

    return <>
        <button className='searchButton' onClick={() => addAllCerts()}><FaDownload /></button>

        <aside style={{ display: 'none' }}>
            {certificates?.map(certificateItem => {
                return certificateItem?.hash ? <QRCode id={`canva-${certificateItem.hash}`}
                    size={100}
                    value={`${urlWebsite}/validar-certificado/${certificateItem.hash}`} renderAs="canva" /> : <></>
            })}
        </aside>
    </>
}


export const downloadCertificate = () => {





    return <> <PrepareModal />


    </>



}

export default downloadCertificate